import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

const DrawdownForecastChart = ({ data, params }) => {
  const processData = (dataArray) => dataArray.map(value => Math.max(value, 0.01));

  const chartData = useMemo(() => {
    if (!data?.simulations) return { labels: [], datasets: [] };

    const labels = Array.from({ length: params.n_years + 1 }, (_, i) => `${i}`);

    const datasets = data.simulations.map((sim, index) => ({
      label: `Simulation ${index + 1}`,
      data: processData(sim.data),
      fill: false,
      borderColor: "rgba(128, 128, 128, 0.1)",
      borderWidth: 1,
      order: 2,
      tension: 0.4,
      pointRadius: 0,
    }));

    if (data.median_simulation?.data) {
      datasets.push({
        label: "Median Simulation",
        data: processData(data.median_simulation.data),
        fill: false,
        borderColor: "rgba(255, 0, 0, 1)",
        borderWidth: 3,
        tension: 0.4,
        pointRadius: 0,
        zIndex: 1,
      });
    }

    return { labels, datasets };
  }, [data, params.n_years]);

  const options = useMemo(() => {
    const minValue = Math.min(...data.simulations.flatMap(sim => sim.data), ...data.median_simulation.data);
  const maxValue = Math.max(...data.simulations.flatMap(sim => sim.data), ...data.median_simulation.data);
  const startCapital = params.start_capital;

  return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          title: {
            display: true,
            text: "Years",
            font: { size: 14, weight: "bold" },
          },
        },
        y: {
          type: 'logarithmic',
          min: Math.min(startCapital / 10, minValue),
          max: Math.max(startCapital * 2, maxValue),
          title: {
            display: true,
            text: "Portfolio Value (£)",
            font: { size: 14, weight: "bold" },
          },
          ticks: {
            callback: (value) => {
              if (value >= 1000000) return `£${(value / 1000000).toFixed(1)}M`;
              if (value >= 1000) return `£${(value / 1000).toFixed(0)}K`;
              if (value >= 1) return `£${value.toFixed(0)}`;
              return `£${value.toFixed(2)}`;
            },
          },
        },
      },
      plugins: {
        legend: {
            display: true,
            position: 'top',
            labels: {
              usePointStyle: true,
              generateLabels: (chart) => {
                return [

                  {
                    text: 'Median Simulation',
                    fillStyle: 'rgba(255, 0, 0, 1)',
                    hidden: false,
                    lineCap: 'butt',
                    lineDash: [],
                    lineDashOffset: 0,
                    order: 1,
                    lineJoin: 'miter',
                    lineWidth: 3,
                    strokeStyle: 'rgba(255, 0, 0, 1)',
                    pointStyle: 'line'
                  },
                  {
                    text: 'Individual Simulations',
                    fillStyle: 'rgba(128, 128, 128, 0.1)',
                    hidden: false,
                    lineCap: 'butt',
                    lineDash: [],
                    lineDashOffset: 0,
                    lineJoin: 'miter',
                    lineWidth: 1,
                    strokeStyle: 'rgba(128, 128, 128, 0.1)',
                    pointStyle: 'line'
                  }
                ];
              }
            }
          },
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = context.dataset.label || '';
              const value = context.parsed.y;
              if (value >= 1) return `${label}: £${value.toLocaleString()}`;
              return `${label}: £${value.toFixed(2)}`;
            },
          },
        },
      },
    };
  }, [data, params.start_capital]);

  return <Line data={chartData} options={options} height={500} />;
};

export default React.memo(DrawdownForecastChart);