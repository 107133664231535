import React, { useEffect, useState, useMemo } from "react";
import { axiosInstance } from "../../axiosConfig";
import annotationPlugin from "chartjs-plugin-annotation";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import "chart.js/auto";
import {
  Button,
  Drawer,
  Slider,
  Typography,
  Box,
  Grid,
  Tabs,
  Tab,
  useMediaQuery,
  IconButton,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../theme";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "../../App.css";
import SavingForecastChart from "./SavingForexastChart";
import DrawdownForecastChart from "./DrawDownForecastChart";
import { ExportButton } from "./StrategyContent";
Chart.register(annotationPlugin);

const MonteCarloChart = ({ meanReturn, volatility }) => {
  const [savingData, setSavingData] = useState(null);
  const [drawdownData, setDrawdownData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [savingParams, setSavingParams] = useState({
    annual_inflation: 0.02,
    initial_savings: 20000,
    // mean_return: returnann,
    n_sims: 100,
    n_years: 20,
    savings_growth_rate: 0.02,
    start_capital: 10000,
    target_amount: 600000,
    // volatility: std
  });
  const [drawdownParams, setDrawdownParams] = useState({
    annual_inflation: 0.028,
    average_return_rate: 0.07,
    income_growth_rate: 0.02,
    initial_income: 30000,
    n_sims: 200,
    n_years: 30,
    start_capital: 600000,
    volatility: 0.14,
    use_gk_guardrails: false,
  });
  const [showWrapper, setShowWrapper] = useState(true);

  const fetchSavingData = () => {
    const query = new URLSearchParams(savingParams).toString();

    const apiUrl = `/montecarlo/performance?${query}`;

    axiosInstance
      .get(apiUrl)
      .then((response) => {
        setSavingData(response.data);
      })
      .catch((error) => console.error("Error fetching saving data:", error));
  };

  const fetchDrawdownData = () => {
    const { volatility, ...otherParams } = drawdownParams;
    const roundedVolatility = parseFloat(volatility.toFixed(2));

    const query =
      new URLSearchParams({
        ...otherParams,
        annual_inflation: otherParams.annual_inflation.toString(),
        average_return_rate: otherParams.average_return_rate.toString(),
        income_growth_rate: otherParams.income_growth_rate.toString(),
        initial_income: otherParams.initial_income.toString(),
        n_sims: otherParams.n_sims.toString(),
        n_years: otherParams.n_years.toString(),
        start_capital: otherParams.start_capital.toString(),
        use_gk_guardrails: otherParams.use_gk_guardrails.toString(),
      }).toString() + `&volatility=${roundedVolatility}`;
    const apiUrl = `/montecarlo/drawdown?${query}`;

    axiosInstance
      .get(apiUrl)
      .then((response) => {
        setDrawdownData(response.data);
      })
      .catch((error) => console.error("Error fetching drawdown data:", error));
  };

  const currentData = tabValue === 0 ? savingData : drawdownData;

  useEffect(() => {
    fetchSavingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (event) => {
    setDrawdownParams((prev) => ({
      ...prev,
      use_gk_guardrails: event.target.checked,
    }));
  };

  const handleSliderChange =
    (paramName, isDrawdown = false) =>
    (event, newValue) => {
      if (isDrawdown) {
        setDrawdownParams((prevParams) => ({
          ...prevParams,
          [paramName]: newValue,
        }));
      } else {
        setSavingParams((prevParams) => ({
          ...prevParams,
          [paramName]: newValue,
        }));
      }
    };

  const handleDrawdownParamChange = (param) => (event, newValue) => {
    setDrawdownParams((prev) => ({
      ...prev,
      [param]: newValue,
    }));
  };

  const handleApply = () => {
    if (tabValue === 0) {
      fetchSavingData();
    } else {
      fetchDrawdownData();
    }
    setDrawerOpen(false);
    setShowWrapper(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setSavingParams((prevParams) => ({
      ...prevParams,
      mean_return: Number(meanReturn),
      volatility: Number(volatility),
    }));
  }, [meanReturn, volatility]);

  useEffect(() => {
    setDrawdownParams((prevParams) => ({
      ...prevParams,
      average_return_rate: Number(meanReturn),
      volatility: Number(volatility),
    }));
  }, [meanReturn, volatility]);

  const formatValue = (value, isCurrency = false, isPercentage = false) => {
    if (isCurrency) {
      return `£${value.toLocaleString()}`;
    }
    if (isPercentage) {
      return `${(value * 100).toFixed(1)}%`;
    }
    return value.toString();
  };
  const handleResetSaving = () => {
    setSavingParams((prevParams) => ({
      ...prevParams,
      mean_return: Number(meanReturn),
      volatility: Number(volatility),
    }));
  };

  const handleResetDrawDown = () => {
    setDrawdownParams((prevParams) => ({
      ...prevParams,
      average_return_rate: Number(meanReturn),
      volatility: Number(volatility),
    }));
  };

  const renderSlider = (
    label,
    value,
    onChange,
    min,
    max,
    step,
    isCurrency = false,
    isPercentage = false,
    tooltip = "",
    uniqueKey = ""
  ) => (
    <Box mb={3} key={uniqueKey}>
      <Typography gutterBottom>
        {label}
        <Tooltip title={tooltip} arrow enterTouchDelay={0}>
          <IconButton size="small" color="primary">
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
      <Slider
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) =>
          formatValue(value, isCurrency, isPercentage)
        }
      />
     <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="caption" color="text.secondary">
        {formatValue(min, isCurrency, isPercentage)}
      </Typography>
      <Typography variant="body2" color="primary" fontWeight="bold">
         {formatValue(value, isCurrency, isPercentage)}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {formatValue(max, isCurrency, isPercentage)}
      </Typography>
    </Box>
    </Box>
  );

  const renderSavingForecastSettings = () => (
    <Box sx={{ padding: isMobile ? "10px" : "20px" }}>
      {renderSlider(
        "Time to retirement (in Years)",
        savingParams.n_years,
        handleSliderChange("n_years"),
        1,
        40,
        1,
        false,
        false,
        "The number of years of investment over which you want to run the simulations",
        "saving-years"
      )}
      {renderSlider(
        "Initial capital invested",
        savingParams.start_capital,
        handleSliderChange("start_capital"),
        0,
        1000000,
        10000,
        true,
        false,
        "The amount of money in your investment portfolio at the beginning of each simulation",
        "saving-start-capital"
      )}
      {renderSlider(
        "Annual investment return (in %)",
        savingParams.mean_return ?? 0,
        handleSliderChange("mean_return"),
        0,
        0.2,
        0.01,
        false,
        true,
        "The average annual return on your investment portfolio during the investment period.",
        "saving-mean-return"
      )}
      {renderSlider(
        "Annual investment volatility (in %)",
        savingParams.volatility ?? 0,
        handleSliderChange("volatility"),
        0,
        0.25,
        0.01,
        false,
        true,
        "The volatility (typical annual percentage price movement) of your investment portfolio.",
        "saving-volatility"
      )}
      <Button
        variant="outlined"
        onClick={handleResetSaving}
        sx={{ mt: 0, mb: 3 }}
      >
        Reset to portfolio nominal data
      </Button>
      {renderSlider(
        "Target Amount (£)",
        savingParams.target_amount,
        handleSliderChange("target_amount"),
        100000,
        2000000,
        100000,
        true,
        false,
        "The amount of savings you want to have at the end of the investment period.",
        "saving-target-amount"
      )}
      {renderSlider(
        "Annual inflation (in %)",
        savingParams.annual_inflation,
        handleSliderChange("annual_inflation"),
        0,
        0.15,
        0.001,
        false,
        true,
        "The average rate of inflation for your living expenses.",
        "saving-annual-inflation"
      )}
      {renderSlider(
        "Initial annual amount invested (in £)",
        savingParams.initial_savings,
        handleSliderChange("initial_savings"),
        0,
        100000,
        1000,
        true,
        false,
        "The annual amount you will invest initially.",
        "saving-initial-savings"
      )}
      {renderSlider(
        "Annual investment growth rate (%)",
        savingParams.savings_growth_rate,
        handleSliderChange("savings_growth_rate"),
        0,
        0.15,
        0.005,
        false,
        true,
        "The rate at which your savings will grow each year as your income increases.",
        "saving-growth-rate"
      )}
      {renderSlider(
        "Number of Simulations",
        savingParams.n_sims,
        handleSliderChange("n_sims"),
        10,
        2000,
        10,
        false,
        false,
        "The number of simulations to run (more are slower but statistics are more stable).",
        "saving-simulations"
      )}
    </Box>
  );

  //--------------------------------------------------------------------------------
  const renderDrawdownForecastSettings = () => (
    <Box sx={{ padding: isMobile ? "10px" : "20px" }}>
      {renderSlider(
        "Duration of retirement (in Years)",
        drawdownParams.n_years,
        handleDrawdownParamChange("n_years"),
        1,
        60,
        1,
        false,
        false,
        "The number of retirement years over which you want to run the simulations.",
        "drawdown-years"
      )}
      {renderSlider(
        "Initial capital invested",
        drawdownParams.start_capital,
        handleDrawdownParamChange("start_capital"),
        0,
        2000000,
        10000,
        true,
        false,
        "The amount of money in your investment portfolio at the beginning of retirement.",
        "drawdown-start-capital"
      )}
      {renderSlider(
        "Annual investment return (in %)",
        drawdownParams.average_return_rate  ?? 0,
        handleDrawdownParamChange("average_return_rate"),
        0,
        0.2,
        0.01,
        false,
        true,
        "The average annual return on your investment portfolio during the investment period.",
        "saving-mean-return"
      )}
      {renderSlider(
        "Annual investment volatility (in %)",
        drawdownParams.volatility ?? 0,
        handleDrawdownParamChange("volatility"),
        0,
        0.25,
        0.01,
        false,
        true,
        "The volatility (typical annual percentage price movement) of your investment portfolio.",
        "drawdown-volatility"
      )}
      <Button
        variant="outlined"
        onClick={handleResetDrawDown}
        sx={{ mt: 0, mb: 3 }}
      >
        Reset Mean Return & Volatility
      </Button>
      {renderSlider(
        "Annual inflation (in %)",
        drawdownParams.annual_inflation,
        handleDrawdownParamChange("annual_inflation"),
        0,
        0.15,
        0.001,
        false,
        true,
        "The average rate of inflation for your living expenses.",
        "drawdown-annual-inflation"
      )}
      {renderSlider(
        "Initial annual income (in £)",
        drawdownParams.initial_income,
        handleDrawdownParamChange("initial_income"),
        0,
        100000,
        1000,
        true,
        false,
        "The annual retirement income which you want to have initially in addition to your state pension, rental income etc.",
        "drawdown-initial-income"
      )}
      {renderSlider(
        "Income growth rate (%)",
        drawdownParams.income_growth_rate,
        handleDrawdownParamChange("income_growth_rate"),
        0,
        0.15,
        0.001,
        false,
        true,
        "The rate at which your income will grow each year to match inflation.",
        "drawdown-income-growth-rate"
      )}
      {renderSlider(
        "Number of simulations",
        drawdownParams.n_sims,
        handleDrawdownParamChange("n_sims"),
        1,
        2000,
        1,
        false,
        false,
        "The number of simulations to run (more are slower but statistics are more stable)",
        "drawdown-simulations"
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={drawdownParams.use_gk_guardrails}
            onChange={handleCheckboxChange}
            name="use_gk_guardrails"
          />
        }
        label="Use Guyton-Klinger Guardrails"
      />
    </Box>
  );

  const calculateSavingDistributionData = (data) => {
    if (
      !data ||
      !data.terminal_distribution ||
      !data.terminal_distribution.data
    ) {
      return { labels: [], datasets: [{ label: "Frequency", data: [] }] };
    }

    const values = data.terminal_distribution.data;
    const binSize = 10000;
    const manualBins = {};

    values.forEach((value) => {
      const binIndex = Math.floor(value / binSize) * binSize;
      manualBins[binIndex] = (manualBins[binIndex] || 0) + 1;
    });

    const sortedBins = Object.entries(manualBins).sort(
      ([a], [b]) => Number(a) - Number(b)
    );

    const labels = sortedBins.map(([key]) => Number(key));
    const frequencyData = sortedBins.map(([, value]) => value);

    return {
      labels,
      datasets: [
        {
          label: "Frequency",
          data: frequencyData,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const calculateDrawdownDistributionData = (data) => {
    if (
      !data ||
      !data.terminal_distribution ||
      !data.terminal_distribution.data
    ) {
      return { labels: [], datasets: [{ label: "Frequency", data: [] }] };
    }

    const values = data.terminal_distribution.data;
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    const binCount = 10;
    const binSize = (maxValue - minValue) / binCount;

    const bins = Array(binCount).fill(0);
    const binMidpoints = [];

    values.forEach((value) => {
      const binIndex = Math.min(
        Math.floor((value - minValue) / binSize),
        binCount - 1
      );
      bins[binIndex]++;
    });

    for (let i = 0; i < binCount; i++) {
      const start = minValue + i * binSize;
      const end = start + binSize;
      const midpoint = (start + end) / 2;
      binMidpoints.push(midpoint);
    }

    return {
      labels: binMidpoints,
      datasets: [
        {
          label: "Frequency",
          data: bins,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  };
  const terminalDistributionData = useMemo(() => {
    if (tabValue === 0) {
      return calculateSavingDistributionData(savingData);
    } else {
      return calculateDrawdownDistributionData(drawdownData);
    }
  }, [tabValue, savingData, drawdownData]);

  const barChartOptions = useMemo(() => {
    const baseOptions = {
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Frequency",
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      maintainAspectRatio: false,
    };

    if (tabValue === 0) {
      // Saving Forecast (Performance)
      return {
        ...baseOptions,
        scales: {
          ...baseOptions.scales,
          x: {
            type: "linear",
            title: {
              display: true,
              text: "Final Amount (£)",
            },
            ticks: {
              callback: function (value) {
                return "£" + value.toLocaleString();
              },
            },
          },
        },
        plugins: {
          ...baseOptions.plugins,
          annotation: {
            annotations: {
              medianLine: {
                type: "line",
                xMin: currentData?.terminal_distribution?.median,
                xMax: currentData?.terminal_distribution?.median,
                borderColor: "rgb(255, 99, 132)",
                borderWidth: 2,
                label: {
                  content: "Median",
                  enabled: true,
                  position: "top",
                },
              },
              percentile5Line: {
                type: "line",
                xMin: currentData?.terminal_distribution?.percentile_5,
                xMax: currentData?.terminal_distribution?.percentile_5,
                borderColor: "rgb(75, 192, 192)",
                borderWidth: 2,
                label: {
                  content: "5th percentile",
                  enabled: true,
                  position: "top",
                },
              },
              percentile95Line: {
                type: "line",
                xMin: currentData?.terminal_distribution?.percentile_95,
                xMax: currentData?.terminal_distribution?.percentile_95,
                borderColor: "rgb(153, 102, 255)",
                borderWidth: 2,
                label: {
                  content: "95th percentile",
                  enabled: true,
                  position: "top",
                },
              },
            },
          },
        },
      };
    } else {
      //  Drawdown Forecast
      return {
        ...baseOptions,
        scales: {
          ...baseOptions.scales,
          x: {
            type: "linear",
            title: {
              display: true,
              text: "Final Amount (£)",
            },
            ticks: {
              callback: function (value) {
                return "£" + Math.round(value).toLocaleString();
              },
            },
          },
        },
        plugins: {
          ...baseOptions.plugins,
          tooltip: {
            callbacks: {
              title: (context) => {
                const value = context[0].parsed.x;
                const index = context[0].dataIndex;
                const labels = context[0].chart.data.labels;
                const binSize =
                  index < labels.length - 1
                    ? labels[index + 1] - labels[index]
                    : labels[index] - labels[index - 1];
                const start = value - binSize / 2;
                const end = value + binSize / 2;
                return `Range: £${Math.round(
                  start
                ).toLocaleString()} - £${Math.round(end).toLocaleString()}`;
              },
              label: (context) => `Frequency: ${context.parsed.y}`,
            },
          },
          annotation: {
            annotations: {
              medianLine: {
                type: "line",
                xMin: currentData?.terminal_distribution?.median,
                xMax: currentData?.terminal_distribution?.median,
                borderColor: "rgb(255, 99, 132)",
                borderWidth: 2,
                label: {
                  content: "Median",
                  enabled: true,
                  position: "top",
                },
              },
              percentile5Line: {
                type: "line",
                xMin: currentData?.terminal_distribution?.percentile_5,
                xMax: currentData?.terminal_distribution?.percentile_5,
                borderColor: "rgb(75, 192, 192)",
                borderWidth: 2,
                label: {
                  content: "5th percentile",
                  enabled: true,
                  position: "start",
                  backgroundColor: "rgb(75, 192, 192)",
                  color: "white",
                  padding: 4,
                  font: {
                    weight: "bold",
                  },
                },
              },
              percentile95Line: {
                type: "line",
                xMin: currentData?.terminal_distribution?.percentile_95,
                xMax: currentData?.terminal_distribution?.percentile_95,
                borderColor: "rgb(153, 102, 255)",
                borderWidth: 2,
                label: {
                  content: "95th percentile",
                  enabled: true,
                  position: "start",
                  backgroundColor: "rgb(153, 102, 255)",
                  color: "white",
                  padding: 4,
                  font: {
                    weight: "bold",
                  },
                },
              },
            },
          },
        },
      };
    }
  }, [
    currentData?.terminal_distribution?.median,
    currentData?.terminal_distribution?.percentile_5,
    currentData?.terminal_distribution?.percentile_95,
    tabValue,
  ]);

  const { median, percentile_5, percentile_95 } =
    currentData?.terminal_distribution || {};
  const targetMedian = currentData?.time_to_target?.median;
  const targetPercentile_5 = currentData?.time_to_target?.percentile_5;
  const targetPercentile_95 = currentData?.time_to_target?.percentile_95;
  const ratio_successful = currentData?.terminal_distribution.ratio_successful;

  const time_to_depletionMedian = currentData?.time_to_depletion?.median;
  const time_to_depletionPercentile_5 =
    currentData?.time_to_depletion?.percentile_5;
  const time_to_depletionPercentile_95 =
    currentData?.time_to_depletion?.percentile_95;

  const LegendItem = ({ color, label }) => (
    <Box display="flex" alignItems="center" mr={2}>
      <Box width={12} height={12} bgcolor={color} mr={1} />
      <Typography variant="caption">{label}</Typography>
    </Box>
  );

  return (
    <div style={{ position: "relative" }}  id="terminalDistributionChart">
        {!showWrapper && (
            <Grid container spacing={2} justifyContent="end" sx={{position: 'absolute', top: '-19px'}}>
            <Button onClick={() => setDrawerOpen(true)}>
            <span className="me-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Hand-Held-Tablet-Writing--Streamline-Core-Remix" height="14" width="14"><desc>Hand Held Tablet Writing Streamline Icon: https://streamlinehq.com</desc><g id="Free Remix/Computer Devices/hand-held-tablet-writing--tablet-kindle-device-electronics-ipad-writing-digital-paper-notepad"><path id="Union" fill="#008ED1" fill-rule="evenodd" d="M12.0133 0.146447c-0.0938 -0.0938331 -0.2211 -0.1465169062 -0.3538 -0.1464469304C11.5268 0.0000711184 11.3996 0.0528901 11.3058 0.146823L6.60583 4.85682c-0.07653 0.07669 -0.12608 0.17616 -0.14119 0.28345l-0.3 2.13c-0.02184 0.15509 0.03025 0.31146 0.14075 0.42247 0.1105 0.11101 0.26662 0.16382 0.42182 0.14269l2.13 -0.29c0.10831 -0.01475 0.20881 -0.06458 0.2861 -0.14188l4.70999 -4.71c0.1953 -0.19526 0.1953 -0.51184 0 -0.7071l-1.84 -1.840003ZM1.27637 1.625c0 -0.20711 0.16789 -0.375 0.375 -0.375h6.875c0.34517 0 0.625 -0.279822 0.625 -0.625S8.87154 6.96e-8 8.52637 6.96e-8h-6.875C0.753904 6.96e-8 0.0263672 0.727537 0.0263672 1.625v10.75c0 0.8975 0.7275368 1.625 1.6250028 1.625h9.00003c0.8974 0 1.625 -0.7275 1.625 -1.625v-4.75c0 -0.34518 -0.2799 -0.625 -0.625 -0.625 -0.3452 0 -0.625 0.27982 -0.625 0.625v2.8828H1.27637V1.625ZM3.02637 3c0 -0.34518 0.27982 -0.625 0.625 -0.625h2c0.34518 0 0.625 0.27982 0.625 0.625s-0.27982 0.625 -0.625 0.625h-2c-0.34518 0 -0.625 -0.27982 -0.625 -0.625Zm0.625 1.875c-0.34518 0 -0.625 0.27982 -0.625 0.625s0.27982 0.625 0.625 0.625h1c0.34518 0 0.625 -0.27982 0.625 -0.625s-0.27982 -0.625 -0.625 -0.625h-1Z" clip-rule="evenodd" stroke-width="1">
                </path></g></svg></span>
                Edit Settings</Button>
          </Grid>

        )}


      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: isMobile ? "100%" : 500,
            height: "100%",
            overflowY: "auto",
          },
        }}
      >
        <Box
          sx={{
            padding: isMobile ? "15px" : "20px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant={isMobile ? "h6" : "h5"}>Settings</Typography>
            <IconButton onClick={() => setDrawerOpen(false)} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{ mb: 2 }}
            variant={isMobile ? "fullWidth" : "standard"}
          >
            <Tab label="Saving Forecast" />
            <Tab label="Drawdown Forecast" />
          </Tabs>
          <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
            {tabValue === 0 && renderSavingForecastSettings()}
            {tabValue === 1 && renderDrawdownForecastSettings()}
          </Box>

          <Button variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Drawer>

      {showWrapper && (
        <Box className="monte-carlo-chart">
          <Grid
            container
            direction="column"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%", position: "relative" }}
          >
            <Grid item>
              <Button variant="contained" onClick={() => setDrawerOpen(true)}>
                Define Settings To Get Started
              </Button>
            </Grid>
            <Grid item>
              <Typography sx={{ color: "white", textAlign: "center" }}>
                Beware, the computation may freeze your browser for a few
                seconds.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      {currentData ? (
        <>
          <Box sx={{ height: 300, marginBottom: "9rem" }}>
            {tabValue === 0 ? (
              <Typography sx={{ textAlign: "center" }} variant="h6">
                Median £{Math.round(median || 0).toLocaleString()} [5th
                Percentile: £{Math.round(percentile_5 || 0).toLocaleString()}
                {"  "}95th Percentile: £
                {Math.round(percentile_95 || 0).toLocaleString()}]
              </Typography>
            ) : (
              <>
                <Typography sx={{ textAlign: "center" }} variant="h6">
                  Median £{Math.round(median || 0).toLocaleString()} [5th
                  Percentile: £{Math.round(percentile_5 || 0).toLocaleString()}{" "}
                  - 95th Percentile: £
                  {Math.round(percentile_95 || 0).toLocaleString()}]
                </Typography>
                <Typography sx={{ textAlign: "center" }} variant="h6">
                  Money didnt run out over {time_to_depletionMedian} years in{" "}
                  {(
                    currentData.terminal_distribution.ratio_successful * 100
                  ).toFixed(2)}
                  % of simulations
                </Typography>
              </>
            )}
             <Grid container justifyContent="end" alignItems="center">
                <ExportButton chartId="terminalDistributionChart" />
            </Grid>
            <Box display="flex" justifyContent="center" mb={2}>
              <LegendItem color="rgb(255, 99, 132)" label="Median" />
              <LegendItem color="rgb(75, 192, 192)" label="5th Percentile" />
              <LegendItem color="rgb(153, 102, 255)" label="95th Percentile" />
            </Box>


            <Bar
              data={terminalDistributionData}
              options={barChartOptions}
              height={300}
            />
          </Box>

          <Box sx={{ height: 500, paddingBottom: isMobile ? "50px" : "" }}>
            {tabValue === 0 ? (
              <>
                <Typography
                  sx={{ textAlign: "center" }}
                  variant="h6"
                  gutterBottom
                >
                  Target Amount of {savingParams.target_amount.toLocaleString()}{" "}
                  reached in {(ratio_successful * 100).toFixed(2)}% simulations
                </Typography>
                <Typography
                  sx={{ textAlign: "center" }}
                  variant="h6"
                  gutterBottom
                >
                  Median time to reach target:{" "}
                  {targetMedian.toLocaleString() || "N/A"} years [ 5th
                  percentile: {targetPercentile_5.toLocaleString() || "N/A"}{" "}
                  years - 95th percentile:{" "}
                  {targetPercentile_95.toLocaleString() || "N/A"} years ]
                </Typography>
              </>
            ) : (
              <Typography
                sx={{ textAlign: "center" }}
                variant="h6"
                gutterBottom
              >
                <p>
                  {" "}
                  Time money ran out: median {time_to_depletionMedian} years
                  [5th percentile {time_to_depletionPercentile_5.toFixed(2)} -
                  95th [percentile &gt; {time_to_depletionPercentile_95} years]
                </p>
                {currentData.success_rate !== undefined &&
                  ` - Success Rate: ${(currentData.success_rate * 100).toFixed(
                    2
                  )}%`}
              </Typography>
            )}
            {tabValue === 0 ? (
              <SavingForecastChart data={savingData} params={savingParams} />
            ) : (
              <DrawdownForecastChart
                data={drawdownData}
                params={drawdownParams}
              />
            )}
          </Box>
        </>
      ) : (
        <Typography sx={{ textAlign: "center" }} variant="h6">
          {tabValue === 0
            ? "No saving forecast data available."
            : "No drawdown forecast data available."}
          Please apply settings to see results.
        </Typography>
      )}
    </div>
  );
};

export default MonteCarloChart;
