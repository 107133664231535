import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance, fetchWithRetry } from '../axiosConfig';
import { getColorForAssetType } from '../components/strategies/utils';
import axios from 'axios';
import Cookies from 'js-cookie';

export const validateToken = createAsyncThunk(
  'strategy/validateToken',
  async (_, { dispatch }) => {
    const cookieData = Cookies.get('PensionCraftUserData');
    let jwtToken = null;
    let parsedCookie = null;
    let userName = null;

    if (cookieData) {
      try {
        parsedCookie = JSON.parse(cookieData);
        jwtToken = parsedCookie.jwt;
        userName = parsedCookie.name;
        if (!jwtToken) {
          console.error('JWT token not found in cookie');
          window.location.href = 'https://pensioncraft.com/investor-education/login/';
          throw new Error('JWT token not found in cookie');
        }
      } catch (error) {
        console.error('Error parsing cookie data:', error);
        throw error;
      }
    } else {
      console.error('Error reading cookie');
      window.location.href = 'https://pensioncraft.com/investor-education/login/';
      throw new Error('Error reading cookie');
    }

    try {
      const response = await axios.post(
        'https://pensioncraft.com/wp-json/pensioncraft/v1/validate-token',
        { token: jwtToken },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.isValid) {
        if (response.data.message === 'Token refreshed' && response.data.jwt) {
          const newJwt = response.data.jwt;
          parsedCookie.jwt = newJwt;
          Cookies.set('PensionCraftUserData', JSON.stringify(parsedCookie));
        }
        dispatch(setUserData({ name: userName, isMember: response.data.isMember }));
        return response.data;
      } else {
        console.error('Invalid token redirect');
        throw new Error('Invalid token');
      }
    } catch (error) {
      console.error('Error validating token:', error);
      console.error('Invalid token redirect');
      throw error;
    }
  }
);

export const fetchAssets = createAsyncThunk('strategy/fetchAssets', async () => {
  const data = await fetchWithRetry('/assets/');
  return data.map(asset => ({
    ...asset,
    label: asset.ticker,
    description: asset.description,
    tooltip: asset.uk_description,
    asset_type: asset.asset_type,
  }));
});

export const fetchStrategies = createAsyncThunk(
  'strategy/fetchStrategies',
  async (_, { getState }) => {
    const response = await axiosInstance.get('/strategies');
    const assets = getState().strategy.assets;

    return response.data.map(strategy => ({
      ...strategy,
      assets: strategy.assets.map(asset => {
        const fullAssetInfo = assets.find(a => a.ticker === asset.asset_ticker) || {};
        return {
          ...asset,
          asset_type: fullAssetInfo.asset_type || 'Unknown',
          color: getColorForAssetType(fullAssetInfo.asset_type),
        };
      }),
    }));
  }
);


export const fetchPublicStrategies = createAsyncThunk(
  'strategy/fetchPublicStrategies',
  async (_, { getState }) => {
    const response = await axiosInstance.get('/strategies/public');
    const assets = getState().strategy.assets;

    return response.data.map(strategy => ({
      ...strategy,
      assets: strategy.assets.map(asset => {
        const fullAssetInfo = assets.find(a => a.ticker === asset.asset_ticker) || {};
        return {
          ...asset,
          asset_type: fullAssetInfo.asset_type || 'Unknown',
          color: getColorForAssetType(fullAssetInfo.asset_type),
        };
      }),
      isPublic: true,
    }));
  }
);

export const clonePublicStrategy = createAsyncThunk(
  'strategy/clonePublicStrategy',
  async (strategy) => {
    // Remove the id to ensure a new strategy is created
    const { id, ...newStrategy } = strategy;
    const response = await axiosInstance.post('/strategies/', newStrategy);
    return response.data;
  }
);

export const fetchPerformanceData = createAsyncThunk(
  'strategy/fetchPerformanceData',
  async ({
    id,
    start_year,
    end_year,
    rebalancing_frequency_in_years,
    investment_amount,
    transaction_fee_amount,
    transaction_fee_type
  }, { rejectWithValue }) => {
    try {
      const params = {
        start_year,
        end_year,
        rebalancing_frequency_in_years,
        investment_amount
      };

      if (transaction_fee_amount !== undefined) {
        params.transaction_fee_amount = transaction_fee_amount;
      }
      if (transaction_fee_type) {
        params.transaction_fee_type = transaction_fee_type;
      }

      const response = await axiosInstance.get(`/strategies/${id}/performance/`, { params });
      return response.data;
    } catch (error) {
      console.error('error fetchPerformanceData:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const fetchNominalPerformanceData = createAsyncThunk(
  'strategy/fetchNominalPerformanceData',
  async ({
    id,
    start_year,
    end_year,
    rebalancing_frequency_in_years,
    investment_amount,
    transaction_fee_amount,
    transaction_fee_type
  }) => {
    try {
      const params = {
        start_year,
        end_year,
        rebalancing_frequency_in_years,
        investment_amount,
        use_nominal_data: true,
      };

      if (transaction_fee_amount !== undefined && transaction_fee_type) {
        params.transaction_fee_amount = transaction_fee_amount;
        params.transaction_fee_type = transaction_fee_type === 'percentage' ? 'percentage' : 'pounds';
      }

      const response = await axiosInstance.get(`/strategies/${id}/performance/`, { params });
      return response.data;
    } catch (error) {
      console.error('error performance data:', error);
      throw error;
    }
  }
);


export const fetchAnnualizedReturnsVolatility = createAsyncThunk(
  'strategy/fetchAnnualizedReturnsVolatility',
  async ({ id, start_year, end_year }) => {
    const response = await axiosInstance.get(`/strategies/${id}/annualized_returns_volatility/`, {
      params: {
        start_year,
        end_year,
      },
    });

    const assetsAnnualizedReturnsStd = response.data.assets_annualized_returns_std;

    return assetsAnnualizedReturnsStd.map(item => ({
      x: item.annualized_returns_std.std * 100,
      y: item.annualized_returns_std.annualized_returns * 100,
      label: item.description,
      year: new Date().getFullYear(),
    }));
  }
);

export const fetchCladogram = createAsyncThunk(
  'strategy/fetchCladogram',
  async ({ id, start_year, end_year }) => {
    const response = await axiosInstance.get(`/strategies/${id}/cladogram/`, {
      params: {
        start_year,
        end_year,
      },
    });

    const { assets, rows } = response.data.cladogram;

    const nodes = assets.map((asset, index) => ({
      name: asset,
      index: index,
      children: []
    }));

    rows.forEach(({ index1, index2, dissimilarity }) => {
      const node1 = nodes[index1];
      const node2 = nodes[index2];
      const newNode = {
        name: `(${dissimilarity.toFixed(4)})`,
        children: [node1, node2],
      };
      nodes.push(newNode);
    });

    return [nodes[nodes.length - 1]];
  }
);

export const fetchDrawdownData = createAsyncThunk(
  'strategy/fetchDrawdownData',
  async ({ id, start_year, end_year, rebalancing_frequency_in_years, investment_amount, transaction_fee_amount, transaction_fee_type }) => {
    const response = await axiosInstance.get(`/strategies/${id}/drawdown/`, {
      params: {
        start_year,
        end_year,
        rebalancing_frequency_in_years,
        investment_amount,
        transaction_fee_amount,
        transaction_fee_type
      },
    });
    return response.data.drawdown;
  }
);

const initialState = {
  user: {
    name: null,
    isMember: null,
  },
  isAuthenticated: false,
  strategies: [],
  publicStrategies: [],
  performanceData: [],
  monteCarloData: null,
  assets: [],
  assetsStatus: 'idle',
  cladogramData: null,
  cladogramStatus: 'idle',
  cladogramError: null,
  status: 'idle',
  publicStatus: 'idle',
  performanceStatus: 'idle',
  monteCarloStatus: 'idle',
  drawdownData: [],
  drawdownStatus: 'idle',
  error: null,
  dataLoaded: false,
  lastLoadedParams: null,
  publicError: null,
  performanceError: null,
  monteCarloError: null,
  drawdownError: null,
  nominalPerformanceData: null,
  nominalPerformanceStatus: 'idle',
  nominalPerformanceError: null,
  transactionFee: 0,
  transactionFeeType: '£',
  yearRange: null,
  settings: {
    // startDate: '2010-01-01',
    // endDate: '2020-01-01',
    rebalancingPeriod: 0,
    transactionFeeValue: '',
    transactionFeeType: 'pounds',
    investmentAmount: 1,
  },
};
const isValidDate = (dateString) => {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
};
const strategySlice = createSlice({
  name: 'strategy',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user.name = action.payload.name;
      state.user.isMember = action.payload.isMember;
      state.isAuthenticated = true;
    },
    setCalculated: (state, action) => {
      state.isCalculated = action.payload;
    },
    addStrategy: (state, action) => {
      const strategyWithColors = {
        ...action.payload,
        assets: action.payload.assets.map(asset => ({
          ...asset,
          color: getColorForAssetType(asset.asset_type),
        })),
      };
      state.strategies.push(strategyWithColors);
    },
    removeStrategy: (state, action) => {
      state.strategies = state.strategies.filter(strategy => strategy.id !== action.payload);
    },
    setAssets: (state, action) => {
      state.assets = action.payload;
    },
    setTransactionFee: (state, action) => {
      state.transactionFee = action.payload.fee;
      state.transactionFeeType = action.payload.feeType;
    },
    setYearRange: (state, action) => {
      state.yearRange = action.payload;
    },
    setSettings: (state, action) => {
      const { startDate, endDate, ...otherSettings } = action.payload;
      state.settings = {
        ...otherSettings,
        startDate: isValidDate(startDate) ? new Date(startDate).toISOString() : state.settings.startDate,
        endDate: isValidDate(endDate) ? new Date(endDate).toISOString() : state.settings.endDate,
      };
    },
    resetStrategyData: (state) => {
      state.performanceData = [];
      state.drawdownData = [];
    },
    resetSettings: (state) => {
      state.settings = {
        // startDate: '2010-01-01',
        // endDate: '2020-01-01',
        rebalancingPeriod: 0,
        transactionFeeValue: '',
        transactionFeeType: '-',
        investmentAmount: '1',
      };
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(validateToken.pending, (state) => {
      state.authStatus = 'loading';
    })
    .addCase(validateToken.fulfilled, (state, action) => {
      state.authStatus = 'succeeded';
      state.user.name = action.payload.name;
      state.user.isMember = action.payload.isMember;
      state.isAuthenticated = true;
    })
    .addCase(validateToken.rejected, (state, action) => {
      state.authStatus = 'failed';
      state.isAuthenticated = false;
      state.error = action.error.message;
    })
      .addCase(fetchAssets.pending, (state) => {
        state.assetsStatus = 'loading';
      })
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.assetsStatus = 'succeeded';
        state.assets = action.payload;
      })
      .addCase(fetchAssets.rejected, (state, action) => {
        state.assetsStatus = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchStrategies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStrategies.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.strategies = action.payload;
      })
      .addCase(fetchStrategies.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPublicStrategies.pending, (state) => {
        state.publicStatus = 'loading';
      })
      .addCase(clonePublicStrategy.fulfilled, (state, action) => {
        state.strategies.push(action.payload);
      })
      .addCase(fetchPublicStrategies.fulfilled, (state, action) => {
        state.publicStatus = 'succeeded';
        state.publicStrategies = action.payload;
      })
      .addCase(fetchPublicStrategies.rejected, (state, action) => {
        state.publicStatus = 'failed';
        state.publicError = action.error.message;
      })
      .addCase(fetchPerformanceData.pending, (state) => {
        state.performanceStatus = 'loading';
      })
      .addCase(fetchPerformanceData.fulfilled, (state, action) => {
        state.performanceStatus = 'succeeded';
      state.performanceData = action.payload;
      state.dataLoaded = true;
      state.lastLoadedParams = action.meta.arg;
      })
      .addCase(fetchPerformanceData.rejected, (state, action) => {
        state.performanceStatus = 'failed';
        state.performanceError = action.error.message;
      })
      .addCase(fetchNominalPerformanceData.pending, (state) => {
        state.nominalPerformanceStatus = 'loading';
      })
      .addCase(fetchNominalPerformanceData.fulfilled, (state, action) => {
        state.nominalPerformanceStatus = 'succeeded';
      state.nominalPerformanceData = action.payload;
      state.dataLoaded = true;
      state.lastLoadedParams = action.meta.arg;
      })
      .addCase(fetchNominalPerformanceData.rejected, (state, action) => {
        state.nominalPerformanceStatus = 'failed';
        state.nominalPerformanceError = action.error.message;
      })

      .addCase(fetchAnnualizedReturnsVolatility.pending, (state) => {
        state.annualizedReturnsVolatilityStatus = 'loading';
      })
      .addCase(fetchAnnualizedReturnsVolatility.fulfilled, (state, action) => {
        state.annualizedReturnsVolatilityStatus = 'succeeded';
        state.annualizedReturnsVolatility = action.payload;
      })
      .addCase(fetchAnnualizedReturnsVolatility.rejected, (state, action) => {
        state.annualizedReturnsVolatilityStatus = 'failed';
        state.annualizedReturnsVolatilityError = action.error.message;
      })
      .addCase(fetchCladogram.pending, (state) => {
        state.cladogramStatus = 'loading';
      })
      .addCase(fetchCladogram.fulfilled, (state, action) => {
        state.cladogramStatus = 'succeeded';
        state.cladogramData = action.payload;
      })
      .addCase(fetchCladogram.rejected, (state, action) => {
        state.cladogramStatus = 'failed';
        state.cladogramError = action.error.message;
      })
      .addCase(fetchDrawdownData.pending, (state) => {
        state.drawdownStatus = 'loading';
      })
      .addCase(fetchDrawdownData.fulfilled, (state, action) => {
        state.drawdownStatus = 'succeeded';
        state.drawdownData = action.payload;
      })
      .addCase(fetchDrawdownData.rejected, (state, action) => {
        state.drawdownStatus = 'failed';
        state.drawdownError = action.error.message;
      });
  },
});

export const { addStrategy, removeStrategy, setAssets, setTransactionFee, setCalculated,setYearRange, setSettings, resetStrategyData , resetSettings, setUserData  } = strategySlice.actions;

export default strategySlice.reducer;
