import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";

const SavingForecastChart = ({ data, params }) => {
  const chartData = useMemo(() => {
    if (!data?.simulations) return { labels: [], datasets: [] };

    const labels = Array.from({ length: params.n_years + 1 }, (_, i) => `${i}`);

    const datasets = data.simulations.map((sim, index) => ({
      label: `Simulation ${index + 1}`,
      data: sim.data,
      fill: false,
      borderColor: "rgba(128, 128, 128, 0.1)",
      borderWidth: 1,
      tension: 0.4,
      order: 2,
      pointRadius: 0,
    }));
    if (data.median_simulation?.data) {
        datasets.push({
            label: "Median Simulation",
            data: data.median_simulation.data,
            fill: false,
            borderColor: "rgba(255, 0, 0, 1)",
            pointRadius: 0,
            borderWidth: 2,
            order: 1,
            tension: 0.1,
            borderJoinStyle: "round",
            borderCapStyle: "round",
      });
    }

    return { labels, datasets };
  }, [data, params.n_years]);

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          title: {
            display: true,
            text: "Years",
            font: { size: 14, weight: "bold" },
          },
        },
        y: {
          type: "linear",
          beginAtZero: false,
          title: {
            display: true,
            text: "Portfolio Value (£)",
            font: { size: 14, weight: "bold" },
          },
          ticks: {
            callback: (value) => `£${value.toLocaleString()}`,
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "top",
          labels: {
            usePointStyle: true,
            generateLabels: (chart) => {
              return [
                {
                  text: "Target Amount",
                  fillStyle: "rgba(255, 0, 0, 0.5)",
                  hidden: false,
                  lineCap: "butt",
                  lineDash: [6, 6],
                  lineDashOffset: 0,
                  lineJoin: "miter",
                  lineWidth: 2,
                  strokeStyle: "rgba(255, 0, 0, 0.5)",
                  pointStyle: "line",
                },
                {
                  text: "Median Simulation",
                  fillStyle: "rgba(255, 0, 0, 1)",
                  hidden: false,
                  lineCap: "butt",
                  lineDash: [],
                  lineDashOffset: 0,
                  lineJoin: "miter",
                  lineWidth: 3,
                  strokeStyle: "rgba(255, 0, 0, 1)",
                  pointStyle: "line",
                },
                {
                  text: "Individual Simulations",
                  fillStyle: "rgba(128, 128, 128, 0.1)",
                  hidden: false,
                  lineCap: "butt",
                  lineDash: [],
                  lineDashOffset: 0,
                  lineJoin: "miter",
                  lineWidth: 1,
                  strokeStyle: "rgba(128, 128, 128, 0.1)",
                  pointStyle: "line",
                },
              ];
            },
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = context.dataset.label || "";
              const value = context.parsed.y;
              return `${label}: £${value.toLocaleString()}`;
            },
          },
        },
        annotation: {
          annotations: {
            targetLine: {
              type: "line",
              yMin: params.target_amount,
              yMax: params.target_amount,
              borderColor: "rgba(255, 0, 0, 0.5)",
              borderWidth: 2,
              borderDash: [6, 6],
              label: {
                content: `Target: £${params.target_amount.toLocaleString()}`,
                enabled: true,
                position: "end",
              },
            },
          },
        },
      },
    }),
    [params.target_amount]
  );

  return <Line data={chartData} options={options} height={500} />;
};

export default SavingForecastChart;
